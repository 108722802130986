export const reviewStates = {
  NOT_REVIEWED: 'not_reviewed',
}

export const autoMergeStates = {
  WAITING_APPROVAL: 'waiting_for_approval',
}

export const dupeSetsStates = {
  CREATED: 'created',
  FINISHED: 'write_finished',
  FAILED: 'write_failed',
  QUEUED: 'write_queued',
  MODIFIED: 'modified',
  VIEWED: 'viewed',
  HIGH_MCS_FINISHED: 'high_mcs_write_finished',
  HIGH_MCS_FAILED: 'high_mcs_write_failed',
  HIGH_MCS_QUEUED: 'high_mcs_write_queued',
  NOTIFIED: 'notified_other',
}
