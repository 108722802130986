import { mockHttp } from '../http'

export const getSegmentationReports = () =>
  mockHttp.$get('/mocks/segmentation_reports_list.json')

export const getConvertLeadStatuses = () =>
  mockHttp.$get('/mocks/convert_lead_statuses.json')

// routing
export const getOperators = () => mockHttp.$get('/mocks/lookup_operators.json')

// governance rules
export const getGovernanceSplitRules = () =>
  mockHttp.$get('/mocks/governance_split_rules.json')
