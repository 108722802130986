import http from '../http'
import { mapToArray, mapToObject } from '../helpers/mappers'

const ACCOUNT_RESPONSE_PATHS = {
  id: 'id',
  name: 'name',
  url: 'url',
  emailDomain: 'email_domain',
}

const ACCOUNT_REQUEST_PATHS = {
  name: 'name',
  url: 'url',
  email_domain: 'emailDomain',
}

export const getAccounts = () => {
  return http.$get('/admin/accounts').then((response) => {
    return mapToArray(response.data, ACCOUNT_RESPONSE_PATHS)
  })
}

export const updateAccount = (accountId, payload) => {
  return http
    .$post(
      `/admin/accounts/${accountId}`,
      mapToObject(payload, ACCOUNT_REQUEST_PATHS)
    )
    .then((response) => {
      return mapToArray(response.data, ACCOUNT_RESPONSE_PATHS)
    })
}
