import { httpLegacy } from '../http'
import { mapToObject, mapToArray } from '../helpers/mappers'
import { formatDate } from '@/utils/dates'
import vuetify from '@/plugins/vuetify.js'

const provider = 'si'
const themeColors = vuetify.framework.theme.themes.light

const entityColors = {
  customeraddress: themeColors.warning,
  account: themeColors.primary,
  lead: themeColors.success,
  contact: themeColors['ap-light-blue'],
}

const verificationActionsColors = {
  cancel: themeColors.error,
  save: themeColors.success,
}

const VERIFICATION_RESPONSE_MAPPER = {
  status: 'provider_result.status.code',
  verificationId: 'event_id',
}

const ADDRESS_FIELDS_RESPONSE_MAPPER = {
  line1: 'provider_result.formatted_address.address_1',
  line2: 'provider_result.formatted_address.address_2',
  line3: 'provider_result.formatted_address.address_3',
  city: 'provider_result.formatted_address.city',
  stateOrProvince: 'provider_result.formatted_address.state',
  postalCode: 'provider_result.formatted_address.postal_code',
  country: 'provider_result.formatted_address.country',
}

const ADDRESS_FIELDS_REQUEST_MAPPER = {
  address_1: 'line1',
  address_2: 'line2',
  address_3: 'line3',
  city: 'city',
  state: 'stateOrProvince',
  postal_code: 'postalCode',
  country: 'country',
  // although we not use these fields,
  // the API required them to be sent
  name: '',
  county: '',
  province: '',
}

const ADDRESS_VERIFICATIONS_RESPONSE_MAPPER = {
  id: 'record_id',
  entity: 'entity_type',
  name: 'record_name',
  verifiedBy: 'user_name',
  verifiedAt: 'iso_8601_datetime',
}

function addressVerificationsResponseExtraMapper(mappedItem) {
  return {
    ...mappedItem,
    verifiedAt: formatDate(mappedItem.verifiedAt, 'MMMM dd, yyyy K:mm aaa'),
  }
}

export const authenticateOnCleanVerify = async ({ userId, totp }) => {
  return httpLegacy.$post('/cv/action/authenticate/key=ap__newu_8474_yada/', {
    session: btoa(`${userId}_session_${totp}`),
  })
}

export const getLastVerification = async (entityId, entityType) => {
  return httpLegacy
    .$post('/cv/result/get/key=ap__newu_8474_yada/', {
      provider,
      record_id: entityId,
      entity_type: entityType,
    })
    .then((response) => {
      if (response.result.length === 0) {
        return false
      }

      const lastHit = response.result[response.result.length - 1]

      return {
        status: lastHit.result,
        date: formatDate(
          new Date(lastHit.iso_8601_datetime),
          'MMMM dd, yyyy K:mm aaa'
        ),
      }
    })
}

export const verifyAddress = ({
  userId,
  userName,
  entityId,
  entityType,
  recordName,
  formData,
}) => {
  return httpLegacy
    .$post('/cv/action/verify/key=ap__newu_8474_yada/', {
      data: {
        address: mapToObject(formData, ADDRESS_FIELDS_REQUEST_MAPPER, {
          emptyValue: '',
        }),
      },
      entity_type: entityType,
      field_type: 'address',
      provider,
      record_id: entityId,
      record_name: recordName,
      user: userId,
      user_name: userName,
    })
    .then((response) => {
      return {
        ...mapToObject(response, VERIFICATION_RESPONSE_MAPPER),
        suggestion: mapToObject(response, ADDRESS_FIELDS_RESPONSE_MAPPER),
      }
    })
}

export const trackAcceptedSuggestion = (verificationId) => {
  return httpLegacy.$post(
    '/cv/action/log_user_action/key=ap__newu_8474_yada/',
    {
      event_id: verificationId,
      user_action: 'save',
    }
  )
}

export const trackCancelledSuggestion = (verificationId) => {
  return httpLegacy.$post(
    '/cv/action/log_user_action/key=ap__newu_8474_yada/',
    {
      event_id: verificationId,
      user_action: 'cancel',
    }
  )
}

export const getAddressVerificationsStats = () => {
  return httpLegacy
    .$get('/cv/result/stats/key=ap__newu_8474_yada/')
    .then((response) => {
      let verificationsByEntityStats = []

      for (const prop in response.stats_by_entity) {
        verificationsByEntityStats = [
          ...verificationsByEntityStats,
          {
            label: prop,
            count: response.stats_by_entity[prop],
            color: entityColors[prop],
          },
        ]
      }

      const statsByAction = response.stats_by_action
      const verificationActionsStats = []

      const saveCount = statsByAction.save || 0
      const cancelCount =
        (statsByAction.cancel || 0) + (statsByAction.no_action || 0)

      if (saveCount) {
        verificationActionsStats.push({
          label: 'Saved',
          count: saveCount,
          color: verificationActionsColors.save,
        })
      }

      if (cancelCount) {
        verificationActionsStats.push({
          label: 'Cancelled',
          count: cancelCount,
          color: verificationActionsColors.cancel,
        })
      }

      return {
        verificationsByEntityStats,
        verificationActionsStats,
      }
    })
}

export const getAddressVerifications = (userId) => {
  return httpLegacy
    .$post('/cv/result/report/key=ap__newu_8474_yada/', {
      report_format: 'json',
      user: userId,
    })
    .then((response) => {
      return mapToArray(response.rows, ADDRESS_VERIFICATIONS_RESPONSE_MAPPER, {
        extraMapping: addressVerificationsResponseExtraMapper,
      })
    })
}
