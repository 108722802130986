import http from './http'
import { mapToObject } from './helpers/mappers'

export const signIn = ({ username, password }) => {
  return http
    .$post('/auth/sign_in', { username, password })
    .then((response) => {
      return response.jwt
    })
}

export const inviteUser = ({ email }) => {
  return http.$post('/auth/start_adding_user', { email })
}

const USER_REQUEST_PATHS = {
  registration_id: 'registrationId',
  password: 'password',
  first_name: 'firstName',
  last_name: 'lastName',
}

export const finishUserRegistration = (payload) => {
  return http
    .$post('/auth/finish_adding_user', mapToObject(payload, USER_REQUEST_PATHS))
    .then((response) => {
      return response.jwt
    })
}

export const signUp = ({ email }) => {
  return http.$post('/auth/start_account_signup', { email })
}

const FINISH_SIGN_UP_REQUEST_PATHS = {
  email: 'email',
  registration_id: 'registrationId',
  phone: 'phone',
  password: 'password',
  company_name: 'companyName',
  first_name: 'firstName',
  last_name: 'lastName',
}

const FINISH_SIGN_UP_RESPONSE_PATHS = {
  jwt: 'jwt',
  status: 'status',
  message: 'message',
}

export const finishSignUp = (payload) => {
  return http
    .$post(
      '/auth/finish_account_signup',
      mapToObject(payload, FINISH_SIGN_UP_REQUEST_PATHS)
    )
    .then((response) => {
      return mapToObject(response, FINISH_SIGN_UP_RESPONSE_PATHS)
    })
}
